import Job from '@taxfyle/web-commons/lib/jobs/Job'
import { Store } from 'libx'
import uniq from 'lodash/uniq'
import once from 'lodash/once'
import handleErrors from 'misc/handleErrors'
import { action, observable } from 'mobx'
import { task } from 'mobx-task'
import { extractMessageFromError, isNetworkError } from 'utils/errorUtil'
import { FlashMessageTypes } from '@taxfyle/web-commons/lib/flash-messages/FlashMessageStore'
import { retry } from 'fejl'
import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import { getFeatureToggleClient } from '@taxfyle/web-commons/lib/misc/featureToggles'
import {
  CreateAmendmentRequest,
  TransferJobToClientTeamRequest,
  TransferJobToClientRequest,
  RemoveClientTeamFromJobRequest,
  CompleteJobRequest,
  CancelJobRequest,
  CloseJobAsTestRequest,
  TransferJobToPoolRequest,
  TransferJobToProviderRequest,
  OverrideDeadlineRequest,
  ReopenJobRequest,
} from '@taxfyle/api-internal/internal/job_admin_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { timestampToISO } from '@taxfyle/web-commons/lib/utils/grpcUtil'
import { numberToDecimal } from 'utils/grpcUtil'
import { mapVisibilityToGrpcRequest } from 'domain/Amendment'
import { mapGrpcJobToJobDTOForAdmin } from '@taxfyle/web-commons/lib/jobs/jobGrpcDtoMapper'
import { RealtimeEvent } from '@taxfyle/api-internal/internal/realtime_pb'
import { map, filter } from 'rxjs/operators'

class JobStore extends Store {
  @observable
  jobs

  /**
   * Job events from the realtime channel.
   */
  jobEvents$ = this.rootStore.api.jobsV3.events$.pipe(
    filter((e) => e !== null && e !== undefined),
    map((e) => this.onRemoteUpdate(e))
  )

  constructor() {
    super(...arguments)
    this.jobs = this.collection({
      model: Job,
      idAttribute: 'id',
    })

    this.prepareRealtimeEvents()
  }

  prepareRealtimeEvents() {
    this.jobEvents$.subscribe()
  }

  forConversation(conversationId) {
    return this.jobs.find((x) => x.conversationId === conversationId)
  }

  forWorkspace(workspaceId) {
    return this.jobs.filter((x) => x.workspaceId === workspaceId)
  }

  get jobService() {
    return this.rootStore.api.jobs
  }

  get jobV3Service() {
    return this.rootStore.api.jobsV3
  }

  get jobActionsService() {
    return this.rootStore.api.jobActions
  }

  get jobAnalyticsService() {
    return this.rootStore.api.jobAnalytics
  }

  get jobProviderService() {
    return this.rootStore.api.jobProviders
  }

  get jobAdminV3Service() {
    return this.rootStore.api.jobAdminV3
  }

  @action.bound
  onRemoteUpdate(realtimeEvent) {
    const isJobUpdatedEvent =
      realtimeEvent.getTypeCase() === RealtimeEvent.TypeCase.JOB_UPDATED

    const jobProtoDto = isJobUpdatedEvent
      ? realtimeEvent.getJobUpdated()?.toObject()?.job
      : realtimeEvent.getJobCreated()?.toObject()?.job

    const jobDto = mapGrpcJobToJobDTOForAdmin(jobProtoDto)

    return this.jobs.set(jobDto)
  }

  @handleErrors
  getAggregations(showInactive) {
    return this.jobService.find({
      workspace_id: this.rootStore.sessionStore.workspace.id,
      aggs: true,
      archived: showInactive,
    })
  }

  @handleErrors
  find(query) {
    const { skip, limit, ...rest } = query

    return this.jobService
      .find({
        $limit: limit,
        $skip: skip,
        workspace_id:
          !rest.workspace_id && this.rootStore.sessionStore.workspace.id,
        exclude_fields: [],
        ...rest,
      })
      .then(
        action((result) => {
          result.data.forEach((job) => {
            const userPublicIds = uniq(
              job.members.map((x) => x.user.user_public_id)
            )
            this.rootStore.memberStore.fetchManyByPublicId(
              userPublicIds.map((id) => ({
                workspace_id: job.workspace_id,
                user_public_id: id,
              }))
            )

            if (job.team_ids) {
              this.rootStore.teamStore.fetchManyTeams(job.team_ids)
            }
          })

          const jobs = this.jobs.set(result.data)
          return {
            ...result,
            data: jobs,
          }
        })
      )
  }

  @handleErrors
  async getJob(jobId) {
    const useV3GetJob = getFeatureToggleClient().variation(
      'HQ.UseV3GetJob',
      false
    )

    if (!useV3GetJob) {
      return this.jobService.get(jobId).then(this.jobs.set)
    }

    return this.rootStore.api.jobsV3
      .getJob(jobId)
      .then(mapGrpcJobToJobDTOForAdmin)
      .then(this.jobs.set)
      .catch((err) => {
        // when job is not found, return archive true
        if (err.code === 5) {
          return { id: jobId, archived: true }
        }

        throw err
      })
  }

  @task
  async fetchJobsForCustomer(params) {
    const { user_id, status, limit = 30, skip = 0 } = params
    return this.find({
      limit,
      skip,
      customerUserId: user_id,
      ...(status && { status: status }),
      $sort: { date_created: 'desc' },
    })
  }

  @task
  async fetchJobsForProvider(params) {
    const { user_id, status = null, limit = 30, skip = 0 } = params
    return this.find({
      limit,
      skip,
      cpaUserId: user_id,
      ...(status && { status: status }),
      $sort: { date_created: 'desc' },
    })
  }

  @task
  async getCurrentJobCount(userId, workspaceId) {
    const result = await this.jobAnalyticsService.find({
      question: 'CURRENT_JOB_COUNT',
      user_id: userId,
      workspace_id: workspaceId,
    })
    return result
  }

  @task
  async getJobsAvailableForPayout(params) {
    const { limit, skip, ...rest } = params

    const result = await this.jobAnalyticsService.find({
      $limit: limit,
      $skip: skip,
      workspace_id: this.rootStore.sessionStore.workspace.id,
      question: 'JOBS_AVAILABLE_FOR_PAYOUT',
      ...rest,
    })

    return result
  }

  @task
  async fetchQualifiedProviders(jobId, cursor) {
    let msg = null

    try {
      const showNetworkError = once(() => {
        msg = this.rootStore.flashMessageStore.create({
          type: FlashMessageTypes.WARN,
          message:
            'There seems to be an issue with your Internet connection. Trying again...',
        })
      })
      const result = await retry(
        (again) =>
          this.jobProviderService.get(jobId, 25, cursor).catch((err) => {
            if (isNetworkError(err)) {
              showNetworkError()
              throw again(err)
            }
            throw err
          }),
        { maxTimeout: 3000, tries: 10 }
      )

      return {
        ...result,
        users: result.users.map((u) =>
          this.rootStore.memberStore.setAndFetch(u)
        ),
      }
    } finally {
      msg?.dismiss()
    }
  }

  async transferJobToPro(jobId, user, reason) {
    const msg = this.rootStore.flashMessageStore.create({
      message: `Transferring job to ${user.displayName}...`,
      inProgress: true,
    })

    try {
      const request = new TransferJobToProviderRequest()
        .setId(jobId)
        .setProviderUserPublicId(user.userPublicId)
        .setReason(reason)
      await this.jobAdminV3Service.transferJobToProvider(request)
      msg
        .done({ message: `Job transferred to ${user.displayName}.` })
        .autoDismiss()
      return await this.getJob(jobId)
    } catch (err) {
      msg.failed(extractMessageFromError(err)).autoDismiss()
      throw err
    }
  }

  async transferJobToClient(jobId, user, reason, asTeamId) {
    const msg = this.rootStore.flashMessageStore.create({
      message: `Transferring job to ${user.displayName}...`,
      inProgress: true,
    })

    try {
      const request = new TransferJobToClientRequest()
        .setId(jobId)
        .setClientUserPublicId(user.userPublicId)
        .setReason(reason)
      await this.jobAdminV3Service.transferJobToClient(request)
      if (asTeamId) {
        await this._transferToClientTeam(jobId, asTeamId)
      }
      msg
        .done({ message: `Job transferred to ${user.displayName}.` })
        .autoDismiss()
      return await this.getJob(jobId)
    } catch (err) {
      msg.failed(extractMessageFromError(err)).autoDismiss()
      throw err
    }
  }

  async notifyPros(jobId) {
    const msg = this.rootStore.flashMessageStore.create({
      message: `Notifying ${T('Web.Common.Providers', 'Pros')}...`,
      inProgress: true,
    })
    try {
      await this.jobActionsService.create({
        action: 'NOTIFY',
        job: jobId,
      })
      msg
        .done({ message: `${T('Web.Common.Providers', 'Pros')} notified.` })
        .autoDismiss()
    } catch (err) {
      msg.failed(extractMessageFromError(err)).autoDismiss()
      throw err
    }
  }

  async addCouponToJob(job, coupon) {
    const msg = this.rootStore.flashMessageStore.create({
      message: `Adding Coupon`,
      inProgress: true,
    })
    try {
      const updatedJob = await this.jobActionsService.create({
        action: 'ADD_COUPON',
        job: job.id,
        coupon_ids: [coupon.id],
        coupon_codes: [coupon.code],
      })
      msg.done(`Coupon added!`).autoDismiss()
      return this.jobs.set(updatedJob)
    } catch (error) {
      throw msg.forError(error)
    }
  }

  async _reopenJob(jobId, reason) {
    const useV3ReopenJob = getFeatureToggleClient().variation(
      'hq.ReopenJobV3',
      false
    )

    if (useV3ReopenJob) {
      const request = new ReopenJobRequest().setId(jobId).setReason(reason)
      return this.jobAdminV3Service.reopenJob(request).then((res) => {
        const dateModified = timestampToISO(res.updateTime)
        return action(() =>
          this.jobs.set({
            id: jobId,
            date_modified: dateModified,
          })
        )
      })
    } else {
      return this.jobActionsService
        .create({
          action: 'REOPEN',
          job: jobId,
          reason: reason,
        })
        .then(action(this.jobs.set))
    }
  }

  async reopenJob(job, opts) {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Reopening job...',
      inProgress: true,
    })
    try {
      await this._reopenJob(job.id, opts.reason)
      msg.done({ message: 'Job reopened! What a day!' }).autoDismiss()
      return this.getJob(job.id)
    } catch (err) {
      msg.failed(extractMessageFromError(err)).autoDismiss()
      throw err
    }
  }

  createJob(name, userId, legendId, legendVersion, billingType, teamId) {
    return this.rootStore.api.jobs
      .create({
        name,
        legend_id: legendId,
        workspace_id: this.rootStore.sessionStore.workspace.id,
        legend_version: legendVersion,
        user_id: userId,
        billing_type: billingType,
        owner_team_id: teamId,
      })
      .then(action(this.jobs.set))
  }

  updateJob(jobId, data) {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Updating Job...',
      inProgress: true,
    })
    return this.rootStore.api.jobs
      .patch(jobId, {
        name: data.name,
        coverage_states: data.states,
        years: data.years,
        answers: data.answers,
        reason: data.reason,
        requested_pro_id: data.requested_pro_id,
        ...(data.billing_type && {
          billing_type: data.billing_type,
        }),
        ...(data.status && { status: data.status }),
      })
      .then(action(this.jobs.set))
      .then(() => msg.done('Done.').autoDismiss())
      .catch((err) => {
        msg.failed(extractMessageFromError(err)).autoDismiss()
        throw err
      })
  }

  async changeLegendVersion(jobId, legendId, legendVersion) {
    await this.rootStore.draftStore.upgradeLegend(jobId)
    return this.getJob(jobId)
  }

  addInvoice(jobId, data) {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Adding Invoice...',
      inProgress: true,
    })
    return this.jobActionsService
      .create({
        action: 'ADD_INVOICE',
        job: jobId,
        invoice: data,
      })
      .then(action(this.jobs.set))
      .then(() => msg.done('Invoice added.').autoDismiss())
      .catch((err) => {
        msg.failed(extractMessageFromError(err)).autoDismiss()
        throw err
      })
  }

  async assignJobToTeam(jobId, data) {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Assigning Job...',
      inProgress: true,
    })

    try {
      if (data.teamId?.length > 0) {
        const request = new TransferJobToClientTeamRequest()
          .setId(jobId)
          .setTeamId(data.teamId)
        await this.jobAdminV3Service.transferJobToClientTeam(request)
        msg.done('Assignment complete.').autoDismiss()
        return await this.getJob(jobId)
      }

      const request = new RemoveClientTeamFromJobRequest().setId(jobId)
      await this.jobAdminV3Service.removeClientTeam(request)
      msg.done('Assignment complete.').autoDismiss()
      return await this.getJob(jobId)
    } catch (err) {
      msg.failed(extractMessageFromError(err)).autoDismiss()
      throw err
    }
  }

  updateDeadline(jobId, data) {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Updating Deadline...',
      inProgress: true,
    })

    const request = new OverrideDeadlineRequest().setJobId(jobId)

    const deadlinePayload = new Timestamp()
    deadlinePayload.fromDate(data.deadline.toDate())
    request.setDeadline(deadlinePayload)

    return this.jobAdminV3Service
      .overrideDeadline(request)
      .then(
        action((res) =>
          this.jobs.set({
            id: jobId,
            deadline_date: data.deadline.toDate(),
            date_modified: res.updateTime,
          })
        )
      )
      .then(() => msg.done('Update complete.').autoDismiss())
      .catch((err) => {
        msg.failed(extractMessageFromError(err)).autoDismiss()
        throw err
      })
  }

  cloneJob(jobId, data) {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Cloning Job...',
      inProgress: true,
    })
    return this.jobActionsService
      .create({
        action: 'CLONE_JOB',
        job: jobId,
        ...data,
      })
      .then(action(this.jobs.set))
      .then(() => msg.done('Cloning complete.').autoDismiss())
      .catch((err) => {
        msg.failed(extractMessageFromError(err)).autoDismiss()
        throw err
      })
  }

  async createAmendment(jobId, amendment) {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Creating amendment...',
      inProgress: true,
    })

    try {
      const request = new CreateAmendmentRequest()
        .setId(jobId)
        .setDescription(amendment.description)
        .setInternalNote(amendment.internalNote)
        .setCustomerAmount(
          numberToDecimal(parseFloat(amendment.customerAmount))
        )
        .setProviderAmount(
          numberToDecimal(parseFloat(amendment.providerAmount))
        )
        .setVisibility(mapVisibilityToGrpcRequest(amendment.visibility))
      await this.jobAdminV3Service.createAmendment(request)
      msg.done('Amendment created.').autoDismiss()
      return await this.getJob(jobId)
    } catch (err) {
      msg.failed(extractMessageFromError(err)).autoDismiss()
      throw err
    }
  }

  async transferToPool(jobId, opts) {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Transferring to pool...',
      inProgress: true,
    })

    try {
      const request = new TransferJobToPoolRequest()
        .setId(jobId)
        .setReason(opts.reason)
      await this.jobAdminV3Service.transferJobToPool(request)
      msg.done('Job transferred back to the pool.').autoDismiss()
      return await this.getJob(jobId)
    } catch (err) {
      msg.failed(extractMessageFromError(err)).autoDismiss()
      throw err
    }
  }

  async holdJob(jobId) {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Updating ...',
      inProgress: true,
    })

    try {
      await this.jobV3Service.holdJob(jobId)
      msg.done('Done.').autoDismiss()
      return await this.getJob(jobId)
    } catch (err) {
      msg.failed(extractMessageFromError(err)).autoDismiss()
      throw err
    }
  }

  async resumeJob(jobId) {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Updating ...',
      inProgress: true,
    })

    if (this.isHoldAndResumeV3) {
      try {
        await this.jobV3Service.resumeJob(jobId)
        msg.done('Done.').autoDismiss()
        return await this.getJob(jobId)
      } catch (err) {
        msg.failed(extractMessageFromError(err)).autoDismiss()
        throw err
      }
    }

    return this.jobActionsService
      .create({
        action: 'RESUME_JOB',
        job: jobId,
      })
      .then(action(this.jobs.set))
      .then(() => msg.done('Done.').autoDismiss())
      .catch((err) => {
        msg.failed(extractMessageFromError(err)).autoDismiss()
        throw err
      })
  }

  async completeJob(jobId, data) {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Completing Job...',
      inProgress: true,
    })

    try {
      const request = new CompleteJobRequest()
        .setId(jobId)
        .setReason(data.reason)
        .setDoBilling(data.doBilling)
        .setBillingMethodId(data.billingMethodId)
      await this.jobAdminV3Service.completeJob(request)
      msg.done('Job completed.').autoDismiss()
      return await this.getJob(jobId)
    } catch (err) {
      msg.failed(extractMessageFromError(err)).autoDismiss()
      throw err
    }
  }

  async cancelJob(jobId, data) {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Cancelling Job...',
      inProgress: true,
    })

    try {
      const request = new CancelJobRequest()
        .setId(jobId)
        .setReason(data.reason)
        .setDoBilling(data.doBilling)
        .setBillingMethodId(data.billingMethodId)
      await this.jobAdminV3Service.cancelJob(request)
      msg.done('Job cancelled.').autoDismiss()
      return await this.getJob(jobId)
    } catch (err) {
      msg.failed(extractMessageFromError(err)).autoDismiss()
      throw err
    }
  }

  async closeJobAsTest(jobId, data) {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Closing as Test Job...',
      inProgress: true,
    })

    try {
      const request = new CloseJobAsTestRequest()
        .setId(jobId)
        .setReason(data.reason)
        .setDoBilling(data.doBilling)
        .setBillingMethodId(data.billingMethodId)
      await this.jobAdminV3Service.closeJobAsTest(request)
      msg.done('Closed job as test.').autoDismiss()
      return await this.getJob(jobId)
    } catch (err) {
      msg.failed(extractMessageFromError(err)).autoDismiss()
      throw err
    }
  }

  addProvider(jobId, opts) {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Adding Pro...',
      inProgress: true,
    })

    return this.jobActionsService
      .create({
        action: 'ADD_PROVIDER',
        job: jobId,
        provider: opts.provider.userPublicId,
        reason: opts.reason,
        type: opts.type || 'SUPPORTING',
        ...(opts.asTeamId && { as_team_id: opts.asTeamId }),
      })
      .then(action(this.jobs.set))
      .then(() => msg.done('Pro added.').autoDismiss())
      .catch((err) => {
        msg.failed(extractMessageFromError(err)).autoDismiss()
        throw err
      })
  }

  async deleteJobs(jobIds) {
    const useV3ArchiveJob = getFeatureToggleClient().variation(
      'HQ.UseV3ArchiveJob',
      false
    )
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Deleting job(s)...',
      inProgress: true,
    })

    const removeJobs = () => {
      jobIds.forEach((jobId) => {
        this.jobs.remove(jobId)
      })

      msg.done('Job(s) deleted.').autoDismiss()
    }

    const archiveJob = async (jobId) => {
      return useV3ArchiveJob
        ? this.jobAdminV3Service.archiveJob(jobId)
        : this.jobService.remove(jobId)
    }

    return Promise.all(jobIds.map(archiveJob))
      .then(removeJobs)
      .catch((err) => {
        msg.failed(extractMessageFromError(err)).autoDismiss()
        throw err
      })
  }

  restoreJobs(jobIds) {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Restoring job(s)...',
      inProgress: true,
    })
    return Promise.all(
      jobIds.map((jobId) => this.jobService.update(jobId, { archived: false }))
    )
      .then(async () => {
        for (const jobId of jobIds) {
          this.jobs.remove(jobId)
        }
        msg.done('Job(s) restored.').autoDismiss()
      })
      .catch((err) => {
        msg.failed(extractMessageFromError(err)).autoDismiss()
        throw err
      })
  }

  async doPayout(jobId, payoutMethodId, description, amount) {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Doing Payout...',
      inProgress: true,
    })
    try {
      const job = await this.jobActionsService.create({
        payoutMethodId,
        description,
        amount,
        action: 'PAY_OUT',
        job: jobId,
      })
      action(this.jobs.set(job))
      msg.done('Payout complete!').autoDismiss()
    } catch (err) {
      msg.failed(extractMessageFromError(err))
    }
  }

  async chargeJob(jobId, billingMethodId, description, amount) {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Doing Charge/Refund...',
      inProgress: true,
    })
    try {
      const job = await this.jobActionsService.create({
        billingMethodId,
        description,
        amount,
        action: 'CHARGE_REFUND',
        job: jobId,
      })
      action(this.jobs.set(job))
      msg.done('Charge/Refund complete!').autoDismiss()
    } catch (err) {
      msg.failed(extractMessageFromError(err))
    }
  }

  removeProvider(jobId, opts) {
    const msg = this.rootStore.flashMessageStore.create({
      message: 'Removing Pro...',
      inProgress: true,
    })
    return this.jobActionsService
      .create({
        action: 'REMOVE_PROVIDER',
        job: jobId,
        provider: opts.provider.userPublicId,
        reason: opts.reason,
      })
      .then(action(this.jobs.set))
      .then(() => msg.done('Pro removed.').autoDismiss())
      .catch((err) => {
        msg.failed(extractMessageFromError(err)).autoDismiss()
        throw err
      })
  }

  async _transferToClientTeam(jobId, teamId) {
    const transferTeamRequest = new TransferJobToClientTeamRequest()
      .setId(jobId)
      .setTeamId(teamId)
    return await this.jobAdminV3Service.transferJobToClientTeam(
      transferTeamRequest
    )
  }
}

export default JobStore
